import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/layouts/header';
import { Grid, Theme, makeStyles, createStyles, Typography } from '@material-ui/core';
import { CONTACT_EMAIL_ADDRESS, FACEBOOK_URL, INSTAGRAM_URL } from '../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        info: {
            marginTop: 25,
            textAlign: 'center',
            height: 'calc(100vh - 350px)',
            '& a': {
                textDecoration: 'none',
                color: theme.palette.primary.light,
                '&:hover': {
                    textDecoration: 'underline',
                    color: theme.palette.primary.main
                }
            }
        }
    })
);

const Contact = () => {
    const classes = useStyles();

    return (
        <Layout page='Contacto'>
            <SEO title='Contacto' />

            <Header siteTitle='Contacto' />

            <main>
                <Grid container direction='column' spacing={2} justify='center' className={classes.info}>
                    <Grid item>
                        <Typography variant='body1'>Puedes envíarnos un correo a</Typography>

                        <a href={`mailto:${CONTACT_EMAIL_ADDRESS}`} rel='noopener noreferrer'>{CONTACT_EMAIL_ADDRESS}</a>
                    </Grid>
                    <Grid item>
                        <Typography variant='body1'>O contáctanos desde alguna de nuestras redes sociales</Typography>

                        <a href={FACEBOOK_URL} target='_blank' rel='noopener noreferrer' style={{ marginRight: 20 }}>Facebook</a>
                        <a href={INSTAGRAM_URL} target='_blank' rel='noopener noreferrer'>Instagram</a>
                    </Grid>
                </Grid>
            </main>
        </Layout>
    );
};

export default Contact